<template>
    <v-container>
        <v-col cols= breakpoints class="mx-auto">
            <v-text-field
                label="Titel"
                v-model= item.Titel
                outlined
            ></v-text-field>
            <v-textarea
                label="Beschreibung"
                v-model= item.Beschreibung
                outlined
            ></v-textarea>
            <v-text-field
                label="Typ"
                v-model= item.Typ
                outlined
            ></v-text-field>
            <h2>Zutaten</h2>
            <hr>
            <v-row class="mt-7" cols= breakpoints v-for="zutat in item.Zutaten" :key= zutat._id>
                <v-text-field
                    class="px-2"
                    label="Zutat"
                    v-model= zutat.Zutat
                    outlined
                ></v-text-field>
                <v-text-field    
                    class="px-2"
                    :label= zutat.Einheit
                    v-model= zutat.Menge
                    outlined
                ></v-text-field>
                 <v-autocomplete
                    clearable
                    v-model="zutat.Einheit"
                    :items="Einheiten"
                    outlined
                    label="Einheit"
                ></v-autocomplete>
                <v-btn
                    @click="removeIndex(zutat)"
                    color="red"
                    class="px-2 my-2"
                    outlined
                >Entfernen</v-btn>
            </v-row>
            <v-btn
                    @click="addZutat()"
                    color="green"
                    class="px-2 my-2"
                    outlined
                >hinzufügen</v-btn>
            <h2>Schritte</h2>
            <hr>
            <v-row class="mt-7" cols= breakpoints v-for="schritt in item.Zubereitung" :key= schritt._id>
                <v-textarea
                    class="px-2"
                    label="Zutat"
                    v-model= schritt.Schritt
                    outlined
                ></v-textarea>
                <v-btn
                    @click="removeZubereitung(schritt)"
                    color="red"
                    class="px-2 my-2"
                    outlined
                >Entfernen</v-btn>
            </v-row>
            <v-row>
                <v-checkbox
                    v-model="item.ShowInPDF"
                    label="Zum Kochbuch hinzufügen"
                ></v-checkbox>
            </v-row>
            <v-btn
                    @click="addSchritt()"
                    color="green"
                    class="px-2 my-2"
                    outlined
                >hinzufügen</v-btn>
        </v-col>
        <hr>
        <v-btn
            @click="save()"
            color="primary"
            class="mt-2 mx-auto"
        >Speichern</v-btn>
        <v-btn
            @click="Delete()"
            color="red"
            class="mt-2 ml-5 mx-auto"
        >Löschen</v-btn>
    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            item: [],
            Einheiten: ['gramm', 'Kg', 'ml', 'Lieter', 'Tl', 'El', 'priese', 'Stück', 'Dosen', 'Packungen', 'Tuben', 'Köpfe']
        }
    },
    computed: {
        /* eslint-disable */ 
      breakpoints () { 
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {
        addZutat() {
            this.item.Zutaten.push({
                Zutat: "",
                Menge: "",
                Einheit: ""
            })
        },
        addSchritt() {
            this.item.Zubereitung.push({
                Schritt: ""
            })
        },
        removeIndex(index) {
            this.item.Zutaten.splice(this.item.Zutaten.indexOf(index), 1);
        },
        removeZubereitung(index) {
            this.item.Zubereitung.splice(this.item.Zubereitung.indexOf(index), 1);
        },
        getRezept() {
            axios.get('https://kjg-api.rezept-zettel.de/api/rezepte/' + this.$route.params.id)
            .then(response => {
                this.item = response.data;
            })
        },
        save() {
            axios.patch('https://kjg-api.rezept-zettel.de/api/rezepte/' + this.$route.params.id, this.item)
            .then(Response => {
                axios.get('https://kjg-api.rezept-zettel.de/api/rezepte/pdf-export')
                this.$router.push('/rezepte')
            })
            .catch(e => {
                console.log(e)
            })
        },
        Delete() {
            axios.delete('https://kjg-api.rezept-zettel.de/api/rezepte/' + this.$route.params.id)
            .then(Response => {
                axios.get('https://kjg-api.rezept-zettel.de/api/rezepte/pdf-export')
                this.$router.push('/rezepte')
            })
            .catch(e => {
                console.log(e)
            })
        }
    },
    created() {
        this.getRezept()
    }
}
</script>

<style>

</style>